import React  from "react"
import useDarkMode from "use-dark-mode";

export  const Logo = ()=>{
	const darkMode = useDarkMode();
    return(
     <svg style={{height:"100%"}}  fill={darkMode.value?"#ffffff":"black"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="130px" height="130px"  viewBox="0 0 612 612"  >
<g>
	<g>
		<g>
			<path d="M371.939,161.162c-12.137-3.467-39.59-5.3-79.389-5.3c-25.024,0-46.001,0.749-46.21,0.756l-0.13,0.005l-0.558-0.006
				c-1.958,0-19.384,0.271-28.325,9.724c-4.037,4.267-5.811,9.73-5.424,16.69c1.669,30.06,26.068,33.633,28.846,33.942
				c3.745,0.416,69.738,1.269,113.182,1.741l0.777,0.087c11.718,2.477,33.604,14.011,32.567,39.936
				c-1.242,30.977-32.589,32.58-42.891,33.106c-0.759,0.038-1.425,0.073-1.98,0.113c-8.218,0.586-89.102,3.418-92.542,3.539
				c-1.084,0.066-28.677,2.115-29.719,33.83c-1.152,35.257,5.221,150,0,110.756l.014,0.34
				c0.011,1.048,0.696,26.127,36.646,27.222c2.063-0.064,28.796-1.377,34.385-25.753l0.577-2.194
				c0.171-1.999,0.26-6.705-0.663-17.171c-1.756-19.926-2.348-64.326-2.372-66.207l-0.048-3.72l3.708-0.283
				c17.592-1.352,31.564-2.23,42.79-2.939c16.601-1.047,27.565-1.737,37.485-3.081c20.309-2.752,35.251-11.087,48.45-27.023
				c2.961-3.572,5.275-7.555,6.884-11.828l0.186-0.423c5.257-10.195,8.34-22.275,10.046-35.904
				c3.755-36.295-10.354-61.712-22.854-76.646C404.939,179.685,385.183,164.947,371.939,161.162z"/>
			
		</g>
	</g>
</g>

</svg>
	
    )
}